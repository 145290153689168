import ApplicationController from "../../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["emailInput", "errors", "modal"];

  initialize() {
    this.focusEmailInput = this.focusEmailInput.bind(this);
  }

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
    this.modalTarget.addEventListener("shown.bs.modal", this.focusEmailInput);
  }

  disconnect() {
    this.modal.dispose();

    this.modalTarget.removeEventListener(
      "shown.bs.modal",
      this.focusEmailInput
    );
  }

  new() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("Admin::Debtor::Invitations#new");
  }

  finalizeNew() {
    this.modal.show();
  }

  create() {
    this.stimulate(
      "Admin::Debtor::Invitations#create",
      { serializeForm: true }
    );
  }

  finalizeCreate() {
    if (this.hasErrorsTarget && !this.errorsTarget.children.length) {
      this.modal.hide();
      this.stimulate();
    }
  }

  resend(event) {
    this.stimulate(
      "Admin::Debtor::Invitations#resend",
      event.currentTarget.dataset.id
    );
  }

  destroy(event) {
    if (confirm(event.currentTarget.dataset.message)) {
      this.stimulate(
        "Admin::Debtor::Invitations#destroy",
        event.currentTarget.dataset.id
      );
    }
  }

  afterDestroy() {
    this.stimulate();
  }

  focusEmailInput() {
    this.emailInputTarget.focus();
    const value = this.emailInputTarget.value;
    this.emailInputTarget.value = "";
    this.emailInputTarget.value = value;
  }
}
