import ApplicationController from "../application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static outlets = ["bank-id--qr-codes", "bank-id--order--results"];
  static targets = ["modal"];
  static values = { destroyPath: String };

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
    this.modalTarget.addEventListener("hide.bs.modal", this.destroy.bind(this));
  }

  disconnect() {
    this.modal.dispose();
  }

  create() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate("BankId::Orders#create");
  }

  finalizeCreate() {
    this.modal.show();
  }

  destroy() {
    if (this.hasBankIdQrCodesOutlet) {
      this.bankIdQrCodesOutlet.disableAutoRefresh();
    }

    if (this.hasBankIdOrderResultsOutlet) {
      this.bankIdOrderResultsOutlet.disableAutoRefresh();
    }

    let token = document.querySelector("meta[name='csrf-token']").content;

    fetch(this.destroyPathValue, {
      method: "DELETE",
      headers: {
        "Accept": "application/json",
        "X-CSRF-Token": token
      }
    });
  }
}
